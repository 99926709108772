@import '~antd/dist/antd.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logo {
    text-align: center;
    margin: 12px;
}

.logo.un-collapse img {
    height: 22px;
}

.logo.collapse img {
    width: 50px;
}

.site-layout.un-collapse{
    margin-left: 200px;
}

.site-layout.collapse {
    margin-left: 80px;
}

.site-layout.collapse .logo img {
    height: 10px;
}

.site-layout .site-layout-background {
    background: #fff;
}

/* use for SPAN in place of A tag */
.a-link {
    cursor: pointer;
    color: #007aff;
}
